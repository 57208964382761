import React from 'react';

import profilePicture from './assets/matster2.png';

import './App.css';

const styles = {
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default () => {
  return (
    <div 
      className="app"
      style={styles.container}
    >
      <img
        className="profilePicture"
        src={profilePicture}
        alt="Matster2"
      />
      <h1
        className="username"
      >
        Matster2
      </h1>
    </div>
  );
};
